import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            myStory: {
              subscription: {
                new: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.myStory.subscription.new.title',
                      defaultMessage:
                        '<b>{memberName}</b> subscribed to your stories until {expireAt, date, ::yyyyMMdd}',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.myStory.subscription.new.message',
                      defaultMessage: 'Upload a new Story today!',
                    },
                    expired: {
                      id: 'pages.layout.header.notifications.mappings.myStory.subscription.new.expired',
                      defaultMessage: 'This content is no longer available',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
