import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            favourite: {
              ...defineMessages({
                added: {
                  id: 'pages.layout.header.notifications.mappings.favourite.added',
                  defaultMessage: '<b>{memberNick}</b> added you to favorites',
                },
              }),
            },
          },
        },
      },
    },
  },
};
