// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import authorization from 'services/routing/authorization';
import location from 'services/routing/location';
import account from 'store/account';

import UI from './account-settings.ui';

interface AccountSettingsElement {
  isNavigationMenuOpen: boolean;
}

const AccountSettings: React.FunctionComponent<AccountSettingsElement> = (props) => {
  const { isNavigationMenuOpen } = props;
  const [authorized] = useReadonlyObservable(
    combineLatest([
      account.onChange$.pipe(
        map((data) => data?.flags?.registrationPending === false),
        distinctUntilChanged()
      ),
      authorization.getAuthorization$('settings')!,
    ]).pipe(
      map(([hasEnrollmentCompleted, hasRequiredProperties]) => hasEnrollmentCompleted && hasRequiredProperties),
      distinctUntilChanged()
    ),
    false
  );

  const active = React.useMemo(
    () => location.current.name === 'settings' && !isNavigationMenuOpen,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.current.name, isNavigationMenuOpen]
  );

  if (!authorized) {
    return null;
  }

  return <UI active={active} />;
};

export type { AccountSettingsElement };
export default AccountSettings;
