import type { InstantPayoutsUpdatedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import messages from './instant-payouts.i18n';
import type { Mapping } from './contracts';
import { mountAvatar } from './utils';

const instantPayoutsUpdated: Mapping<InstantPayoutsUpdatedNotification> = (notification) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isInstantPayoutsEnabled } = notification;

  return {
    title: [
      isInstantPayoutsEnabled
        ? messages.pages.layout.header.notifications.mappings.instantPayouts.updated.title.enabled
        : messages.pages.layout.header.notifications.mappings.instantPayouts.updated.title.disabled,
    ],
    avatar: mountAvatar.system(),
    handleClick: isInstantPayoutsEnabled ? () => navigation.payoutOptions({ page: 'general' }) : undefined,
  };
};

export { instantPayoutsUpdated };
