import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            channel: {
              content: {
                rejected: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.channel.content.rejected.title',
                      defaultMessage: 'Your content was rejected',
                    },
                    noteTitle: {
                      id: 'pages.layout.header.notifications.mappings.channel.content.rejected.noteTitle',
                      defaultMessage: 'Your note was rejected',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.channel.content.rejected.message',
                      defaultMessage: 'Click to find out more',
                    },
                    deleted: {
                      id: 'pages.layout.header.notifications.mappings.channel.content.rejected.deleted',
                      defaultMessage: 'This content is no longer available',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
