import type {
  PerformerReferralConnectionCreatedNotification,
  PerformerReferralConnectionCutNotification,
  PerformerReferralConsentRevokedNotification,
} from 'services/api/notifications-service/data-contracts';
import application from 'services/application';
import navigation from 'services/navigation';

import messages from './referral.i18n';
import type { Mapping } from './contracts';
import { closeNotificationPopover, mountAvatar } from './utils';

const referralConsentRevoked: Mapping<PerformerReferralConsentRevokedNotification> = () => {
  return {
    title: [
      messages.pages.layout.header.notifications.mappings.referral.consent.revoked,
      {
        shareValue: '100%',
      },
    ],
    avatar: mountAvatar.system(),
    handleClick() {
      closeNotificationPopover();
      navigation.statisticsIncomeOverviewOverview();
    },
  };
};

const referralConnectionCreated: Mapping<PerformerReferralConnectionCreatedNotification> = (notification) => {
  const { referralEntityName } = notification;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.referral.connection.created,
      {
        name: referralEntityName,
        commission: '10%',
      },
    ],
    avatar: mountAvatar.system(),
    handleClick() {
      closeNotificationPopover();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      application.Oranum ? navigation.referralsBroadcaster() : navigation.referralsModelReferral();
    },
  };
};

const referralConnectionCut: Mapping<PerformerReferralConnectionCutNotification> = (notification) => {
  const { referralEntityName } = notification;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.referral.connection.cut,
      {
        name: referralEntityName,
      },
    ],
    avatar: mountAvatar.system(),
  };
};

export { referralConsentRevoked, referralConnectionCreated, referralConnectionCut };
