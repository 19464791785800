import type {
  PerformerPromotionTeaserAcceptedNotification,
  PerformerPromotionTeaserParticipantAddedNotification,
  PerformerPromotionTeaserParticipantConfirmationRequiredNotification,
  PerformerPromotionTeaserRejectedNotification,
} from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import type { Mapping } from './contracts';
import messages from './promotion.i18n';
import { mountAvatar } from './utils';

const promotionTeaserAccepted: Mapping<PerformerPromotionTeaserAcceptedNotification> = () => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.promotion.accepted.title],
    avatar: mountAvatar.system(),
  };
};

const promotionTeaserRejected: Mapping<PerformerPromotionTeaserRejectedNotification> = (notification) => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.promotion.rejected.title],
    description: [messages.pages.layout.header.notifications.mappings.promotion.rejected.description],
    avatar: mountAvatar.system(),
    handleClick() {
      navigation.promotionToolsExplicitVideosList({ id: notification.teaserId.toString() });
    },
  };
};

const promotionTeaserParticipantConfirmationRequired: Mapping<
  PerformerPromotionTeaserParticipantConfirmationRequiredNotification
> = (notification) => {
  return {
    title: [
      messages.pages.layout.header.notifications.mappings.promotion.participantConfirmationRequired.title,
      { performerName: notification.author.displayName },
    ],
    description: [
      messages.pages.layout.header.notifications.mappings.promotion.participantConfirmationRequired.description,
    ],
    avatar: mountAvatar.system(),
    handleClick() {
      navigation.promotionToolsExplicitVideosLanding({ openConsentModal: '1' });
    },
  };
};

const promotionTeaserParticipantAdded: Mapping<PerformerPromotionTeaserParticipantAddedNotification> = (
  notification
) => {
  return {
    title: [
      messages.pages.layout.header.notifications.mappings.promotion.participantAdded.title,
      { performerName: notification.author.displayName },
    ],
    avatar: mountAvatar.system(),
  };
};

export {
  promotionTeaserAccepted,
  promotionTeaserRejected,
  promotionTeaserParticipantConfirmationRequired,
  promotionTeaserParticipantAdded,
};
