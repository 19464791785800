import { LiveNotificationEvent } from 'services/websocket/contracts';
import parse from 'utils/parse';

import { NotificationEvent } from './contracts';

/**
 * This mapping only needed when "NotificationEvent" doesn't have a match with "LiveNotificationEvent".
 * E.g. NotificationEvent.'my_content.commented' !== LiveNotificationEvent,'channel.content.commented'
 */
const API_TO_WS_MAPPING: Partial<Record<NotificationEvent, LiveNotificationEvent>> = {
  [NotificationEvent.MyContentCommented]: LiveNotificationEvent.ChannelContentCommented,
  [NotificationEvent.MyContentImageFolderSubscribed]: LiveNotificationEvent.ImagefolderSubscribed,
  [NotificationEvent.MyContentLiked]: LiveNotificationEvent.ChannelContentLiked,
  [NotificationEvent.MyContentVideoSubscribed]: LiveNotificationEvent.VideoSubscribed,
  [NotificationEvent.FavouriteAdded]: LiveNotificationEvent.FavouriteAdded,
  [NotificationEvent.InstantPayoutsUpdated]: LiveNotificationEvent.InstantPayoutsUpdated,
  [NotificationEvent.ProfilePictureAccepted]: LiveNotificationEvent.ProfilePictureAccepted,
  [NotificationEvent.ProfilePictureRejected]: LiveNotificationEvent.ProfilePictureRejected,
  [NotificationEvent.ExcludedAwardGame]: LiveNotificationEvent.ExcludedAwardGame,
  [NotificationEvent.PerformerPromotionTeaserStatusAccepted]: LiveNotificationEvent.PromotionTeaserStatusAccepted,
  [NotificationEvent.PerformerPromotionTeaserStatusRejected]: LiveNotificationEvent.PromotionTeaserStatusRejected,
  [NotificationEvent.PerformerPromotionTeaserParticipantAdded]: LiveNotificationEvent.PromotionTeaserParticipantAdded,
  [NotificationEvent.PerformerPromotionTeaserParticipantConfirmationRequired]:
    LiveNotificationEvent.PromotionTeaserParticipantConfirmationRequired,
};

const getLiveEventNamespace = (event: NotificationEvent): LiveNotificationEvent | undefined => {
  const LIVE_NAMESPACES = Object.values(LiveNotificationEvent);
  const normalizedTargetEvent = parse.toCamelCase(event).toLowerCase();

  return (
    API_TO_WS_MAPPING[event] ||
    LIVE_NAMESPACES.find((namespace) => parse.toCamelCase(namespace).toLowerCase() === normalizedTargetEvent)
  );
};

const getEventFromLiveNamespace = (event: LiveNotificationEvent): NotificationEvent => {
  const EVENTS = Object.values(NotificationEvent);
  const normalizedTargetEvent = parse.toCamelCase(event).toLowerCase();

  return (
    (Object.keys(API_TO_WS_MAPPING).find((namespace) => API_TO_WS_MAPPING[namespace] === event) as NotificationEvent) ||
    EVENTS.find((namespace) => parse.toCamelCase(namespace).toLowerCase() === normalizedTargetEvent)
  );
};

export { getLiveEventNamespace, getEventFromLiveNamespace };
