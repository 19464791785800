import navigation from 'services/navigation';
import type { DirectPayoutUpdatedNotification } from 'services/api/notifications-service/data-contracts';

import messages from './direct-payout.i18n';
import type { Mapping } from './contracts';
import { closeNotificationPopover, mountAvatar } from './utils';

const directPayoutPending: Mapping<DirectPayoutUpdatedNotification> = (response) => {
  const { share } = response;
  const { title } = messages.pages.layout.header.notifications.mappings.directPayout.pending;
  const { message } = messages.pages.layout.header.notifications.mappings.directPayout.pending;

  return {
    avatar: mountAvatar.system(),
    title: [
      title,
      {
        share: Math.round(share * 100),
      },
    ],
    description: [message],
    handleClick() {
      closeNotificationPopover();
      navigation.directPayout();
    },
  };
};

const directPayoutApproved: Mapping<DirectPayoutUpdatedNotification> = (response) => {
  const { share } = response;
  const { title } = messages.pages.layout.header.notifications.mappings.directPayout.approved;
  const modelShare = Math.round(share * 100);

  return {
    avatar: mountAvatar.system(),
    title: [
      title,
      {
        share: modelShare,
        remaining: 100 - modelShare,
      },
    ],
  };
};

const directPayoutDeleted: Mapping<DirectPayoutUpdatedNotification> = () => {
  const { title } = messages.pages.layout.header.notifications.mappings.directPayout.deleted;

  return {
    avatar: mountAvatar.system(),
    title: [title],
  };
};

const directPayoutDisabledBySupport: Mapping<DirectPayoutUpdatedNotification> = () => {
  const { title } = messages.pages.layout.header.notifications.mappings.directPayout.disabledBySupport;

  return {
    avatar: mountAvatar.system(),
    title: [title],
  };
};

export { directPayoutPending, directPayoutApproved, directPayoutDeleted, directPayoutDisabledBySupport };
