import { defineTracking } from 'services/google-analytics';

const category = 'header - user badge';

export default {
  ...defineTracking({
    toggle: {
      category,
      action: 'toggle',
      label: '{status} content',
      description:
        'This event triggered when user toggles header user badge. ' +
        '{status} represents its status, it can be either "open" or "close"',
    },
    switchToStudioView: {
      category,
      action: 'view type switch',
      label: 'switch to Studio View',
      description: 'This event triggered when user clicks to switch back to Studio View.',
    },
    addNewModel: {
      category,
      action: 'redirect',
      label: 'Redirect user to Add Model flow on MSC Portal.',
      description:
        'This event triggered when user clicks on the "add new model" button, ' +
        'which afterwards we redirect to MSC Portal with request to open the Add Model flow.',
    },
  }),
};
