import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            profilePicture: {
              accepted: defineMessages({
                title: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.accepted.title',
                  defaultMessage: 'Your profile photo has been accepted',
                },
                titleMain: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.accepted.titleMain',
                  defaultMessage: 'Your Main Profile Photo has been accepted.',
                },
                titleNonExplicit: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.accepted.titleNonExplicit',
                  defaultMessage: 'Your Non-Explicit Photo has been accepted.',
                },
                titleWillingness: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.accepted.titleWillingness',
                  defaultMessage: 'Your Willingness Photo has been accepted.',
                },
                message: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.accepted.message',
                  defaultMessage: 'You look like a star!',
                },
              }),
              rejected: defineMessages({
                title: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.rejected.title',
                  defaultMessage: 'Your profile photo has been rejected',
                },
                titleMain: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.rejected.titleMain',
                  defaultMessage: 'Your Main Profile Photo has been rejected.',
                },
                titleNonExplicit: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.rejected.titleNonExplicit',
                  defaultMessage: 'Your Non-Explicit Photo has been rejected.',
                },
                titleWillingness: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.rejected.titleWillingness',
                  defaultMessage: 'Your Willingness Photo has been rejected.',
                },
                message: {
                  id: 'pages.layout.header.notifications.mappings.profilePicture.rejected.message',
                  defaultMessage: 'Review our Profile Photo policy and upload a new one',
                },
              }),
            },
          },
        },
      },
    },
  },
};
