import type { FavouriteAddedNotification } from 'services/api/notifications-service/data-contracts';

import messages from './favourite.i18n';
import type { Mapping } from './contracts';
import { intlLocaleDefaultsRTF, mountMessengerLink, mountAvatar } from './utils';

const favouriteAdded: Mapping<FavouriteAddedNotification> = (response) => {
  const {
    member: { memberNick, loyaltyLevel, memberId },
    entanglement: { level, threadId },
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.favourite.added,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, loyaltyLevel),
    handleClick: mountMessengerLink(level, memberId, threadId),
  };
};

export { favouriteAdded };
