import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            loyalfans: {
              underApproval: defineMessages({
                title: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.underApproval.title',
                  defaultMessage: 'Your Loyalfans registration is under approval',
                },
                message: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.underApproval.message',
                  defaultMessage: 'The Loyalfans team is currently reviewing your application',
                },
              }),
              successful: defineMessages({
                title: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.successful.title',
                  defaultMessage: 'Your Loyalfans registration has been approved',
                },
                message: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.successful.message',
                  defaultMessage: 'Your account is ready to get started!',
                },
              }),
              incomplete: defineMessages({
                title: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.incomplete.title',
                  defaultMessage: 'Your Loyalfans registration is incomplete',
                },
                message: {
                  id: 'pages.layout.header.notifications.mappings.loyalfans.incomplete.message',
                  defaultMessage: 'Please finish registering your account to continue',
                },
              }),
            },
          },
        },
      },
    },
  },
};
