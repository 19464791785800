enum NotificationEvent {
  CompetitorRewarded = 'competitor.rewarded',
  ExcludedAwardGame = 'excluded.award.game',
  FavouriteAdded = 'favourite.added',
  FanclubMemberResubscribed = 'fanclub.member_resubscribed',
  FanclubMemberSubscribed = 'fanclub.member_subscribed',
  FanclubMemberUnsubscribed = 'fanclub.member_unsubscribed',
  InstantPayoutsUpdated = 'instant_payouts.updated',
  LoyalfansRegistrationStatusUpdated = 'loyalfans.registration_status.updated',
  MyContentCommented = 'my_content.commented',
  MyContentImageFolderSubscribed = 'my_content.image_folder.subscribed',
  MyContentLiked = 'my_content.liked',
  MyContentVideoSubscribed = 'my_content.video.subscribed',
  MyStoryStorySubscriptionCreated = 'my_story.story.subscription.created',
  PerformerActionRequestsDisabled = 'performer.action_requests.disabled',
  PerformerBattleEnded = 'performer.battle.ended',
  PerformerBattleEnabled = 'performer.battle.enabled',
  PerformerBattleDisabled = 'performer.battle.disabled',
  PerformerChannelContentRejected = 'performer.channel.content.rejected',
  PerformerHotShowEnded = 'performer.hot_show.ended',
  PerformerInteractiveToyDisabled = 'performer.interactive_toy.disabled',
  PerformerPerformerReferralConnectionCreated = 'performer.performer_referral.connection.created',
  PerformerPerformerReferralConnectionCut = 'performer.performer_referral.connection.cut',
  PerformerPerformerReferralConsentRevoked = 'performer.performer_referral.consent.revoked',
  PerformerPromotionTeaserStatusAccepted = 'performer.promotion.teaser.status.accepted',
  PerformerPromotionTeaserStatusRejected = 'performer.promotion.teaser.status.rejected',
  // eslint-disable-next-line max-len
  PerformerPromotionTeaserParticipantConfirmationRequired = 'performer.promotion.teaser.participant.confirmation_required',
  PerformerPromotionTeaserParticipantAdded = 'performer.promotion.teaser.participant.added',
  PerformerStatusUpdated = 'performer.status.updated',
  PerformerStoryItemRejected = 'performer.story.item.rejected',
  PerformerSurveyNotification = 'performer.survey_notification',
  PerformerMobileLiveDisabled = 'performer.mobile_live.disabled',
  PerformerMobileLiveEnabled = 'performer.mobile_live.enabled',
  PerformerVideoCallDisabled = 'performer.video_call.disabled',
  PerformerVideoCallEnabled = 'performer.video_call.enabled',
  PerformerShopPaymentOrderPlaced = 'performer.shop.payment_order.placed',
  ProfilePictureAccepted = 'performer.profile_picture.accepted',
  ProfilePictureRejected = 'performer.profile_picture.rejected',
  PromotoolsPromotionContentStatusAccepted = 'promotools.promotion_content.status.accepted',
  PromotoolsPromotionContentStatusRejected = 'promotools.promotion_content.status.rejected',
  DirectPayoutPending = 'direct_payout.pending',
  DirectPayoutApproved = 'direct_payout.approved',
  DirectPayoutDeleted = 'direct_payout.deleted',
  DirectPayoutDisabledBySupport = 'direct_payout.disabled_by_support',
  InternalPartnerConsentRevoked = 'internal_partner.consent.revoked',
  /**
   * Currently not enabled/on production. Planned to enable it in 2023.
   * @link {https://confluence.doclerholding.com/display/DEVZONE/MSC+-+Notification+System}
   */
  // AchievementLevelChanged = 'achievement.level.changed',
}

export { NotificationEvent };
