import React from 'react';
import useBodyScrollLock from 'enhancers/use-body-scroll-lock';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';
import Popover, { type PopoverElement, type PopoverControl } from 'components/popover';
import breakpoints from 'utils/breakpoints';
import { KeyboardKey } from 'contracts';

import Content from './content';
import { testId } from './notifications.settings';
import styles from './notifications.module.scss';

interface NotificationsElement {
  unread: number;
  onContentToggle: Exclude<PopoverElement['onToggle'], undefined>;
  onRefreshRequest: () => void;
  onMarkAllAsReadRequest: () => void;
}

const Notifications: React.FunctionComponent<NotificationsElement> = (props) => {
  const { unread, onContentToggle, onRefreshRequest, onMarkAllAsReadRequest } = props;
  const [isContentOpen, toggleState] = React.useState<boolean>(false);
  const popoverRef = React.createRef<PopoverControl>();
  const scrollLock = useBodyScrollLock(false);

  const onPopoverToggle: NotificationsElement['onContentToggle'] = React.useCallback(
    (status, direction, mounted) => {
      if (status) onRefreshRequest();

      toggleState(status);

      if (status && breakpoints.max('md')) {
        scrollLock.lock();
      } else {
        scrollLock.unlock();
      }

      if (!status && mounted) {
        onMarkAllAsReadRequest();
      }

      onContentToggle(status, direction, mounted);
    },
    [onRefreshRequest, onContentToggle, scrollLock, onMarkAllAsReadRequest]
  );

  return (
    <Popover
      testId={testId.notifications}
      content={<Content visible={isContentOpen} />}
      position="bottom-right"
      trigger={{
        onClick: (open) => !open,
        onKeyDown: (open, event: React.KeyboardEvent<HTMLDivElement>): boolean => {
          if ([KeyboardKey.Spacebar, KeyboardKey.Enter].includes(event.key as KeyboardKey)) {
            event.stopPropagation();

            return !open;
          }

          return !open;
        },
      }}
      onToggle={onPopoverToggle}
      // eslint-disable-next-line css-modules/no-undef-class
      className={styles.notifications}
      ref={popoverRef}
      closeOnContentClick={false}
    >
      <Html.span
        tabIndex={0}
        testId={testId.badge}
        onKeyDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {unread > 0 && (
          <Html.span className={['text-center m-0 rounded-circle', styles.badgeWrapper]}>
            <Html.span
              testId={testId.badgeCounter}
              typography="fineprint2"
              className={['text-center m-0 rounded-circle', styles.badge]}
            >
              {unread}
            </Html.span>
          </Html.span>
        )}
        <Icon
          testId={testId.icon}
          name={IconList.notificationsOutline}
          className={[styles.icon, isContentOpen && styles.active]}
        />
      </Html.span>
    </Popover>
  );
};

export type { NotificationsElement };
export default React.memo(Notifications);
