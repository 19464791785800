import type { MyStorySubscriptionCreatedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';
import happen from 'utils/happen';

import messages from './my-story.i18n';
import type { Mapping } from './contracts';
import { intlLocaleDefaultsRTF, mountAvatar } from './utils';

const myStoryNewSubscription: Mapping<MyStorySubscriptionCreatedNotification> = (response) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    expireAt,
    member: { memberNick, memberId },
  } = response;

  const expirationDate = new Date(expireAt as string);
  const expired = happen(new Date()).greaterThanOrEqual(expirationDate);

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.myStory.subscription.new.title,
      {
        memberName: memberNick,
        expireAt: expirationDate,
        ...intlLocaleDefaultsRTF,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, null),
    description: [
      expired
        ? messages.pages.layout.header.notifications.mappings.myStory.subscription.new.expired
        : messages.pages.layout.header.notifications.mappings.myStory.subscription.new.message,
    ],
    handleClick: expired
      ? undefined
      : () => {
          return navigation.messenger({ page: 'recommended', threadId: memberId?.toString() });
        },
  };
};

export { myStoryNewSubscription };
