import React from 'react';
import IconHover, { IconList } from 'components/icon-hover';
import Popover from 'components/popover';
import type { PopoverElement, PopoverControl } from 'components/popover';
import { KeyboardKey } from 'contracts';

import Content, { type UserBadgeContent } from './content';
import { testId } from './user-badge.settings';
import styles from './user-badge.module.scss';

interface UserBadgeElement extends UserBadgeContent {
  onToggle: Exclude<PopoverElement['onToggle'], undefined>;
}

const UserBadge: React.FunctionComponent<UserBadgeElement> = (props) => {
  const {
    email,
    showSwitchToStudioButton,
    showAddNewModelButton,
    onAddNewModelRequest,
    onSwitchToStudioRequest,
    onLogoutRequest,
    onToggle,
  } = props;
  const [active, setState] = React.useState<boolean>(false);
  const popoverRef = React.createRef<PopoverControl>();

  const popoverTrigger: PopoverElement['trigger'] = React.useMemo(
    () => ({
      onClick: (open) => !open,
      onKeyDown: (open, event: React.KeyboardEvent<HTMLDivElement>): boolean => {
        if ([KeyboardKey.Spacebar, KeyboardKey.Enter].includes(event.key as KeyboardKey)) {
          event.stopPropagation();

          return !open;
        }

        return !open;
      },
    }),
    []
  );

  const handleOnPopoverToggle: UserBadgeElement['onToggle'] = React.useCallback(
    (status, direction, mounted) => {
      setState(status);

      onToggle(status, direction, mounted);
    },
    [onToggle]
  );

  return (
    <Popover
      testId={testId.userBadge}
      content={
        <Content
          email={email}
          showAddNewModelButton={showAddNewModelButton}
          showSwitchToStudioButton={showSwitchToStudioButton}
          onAddNewModelRequest={onAddNewModelRequest}
          onSwitchToStudioRequest={onSwitchToStudioRequest}
          onLogoutRequest={onLogoutRequest}
        />
      }
      position="bottom-right"
      trigger={popoverTrigger}
      // eslint-disable-next-line css-modules/no-undef-class
      className={styles.userBadge}
      closeOnContentClick={false}
      onToggle={handleOnPopoverToggle}
      ref={popoverRef}
    >
      <IconHover
        testId={testId.userBadgeIcon}
        inactive={IconList.profileOutline}
        active={IconList.profileSolid}
        className={[styles.icon, active && styles.active]}
        forceActive={active}
      />
    </Popover>
  );
};

export type { UserBadgeElement };
export default UserBadge;
