import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            directPayout: {
              pending: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.directPayout.pending.title',
                    defaultMessage:
                      'Your Direct Payout share has been set to ({share}%) by your studio. Please accept the Model and Studio Services Agreement and follow the necessary steps.',
                  },
                  message: {
                    id: 'pages.layout.header.notifications.mappings.directPayout.pending.message',
                    defaultMessage: 'Click to open Direct Payout',
                  },
                }),
              },
              approved: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.directPayout.approved.title',
                    defaultMessage:
                      'Your Direct Payout has been activated. You will receive ({share}%) of your earnings and your studio will get the remaining ({remaining}%).',
                  },
                }),
              },
              deleted: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.directPayout.deleted.title',
                    defaultMessage:
                      'Direct Payouts were disabled by your studio. If you have any questions, please contact your studio.',
                  },
                }),
              },
              disabledBySupport: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.directPayout.disabledBySupport.title',
                    defaultMessage:
                      'Direct Payouts were disabled for your studio by the support team. If you have any questions, please contact our support department.',
                  },
                }),
              },
            },
          },
        },
      },
    },
  },
};
