import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            myContent: {
              commented: defineMessages({
                album: {
                  id: 'pages.layout.header.notifications.mappings.myContent.commented.album',
                  defaultMessage: '<b>{memberName}</b> commented on your album',
                },
                note: {
                  id: 'pages.layout.header.notifications.mappings.myContent.Commented.note',
                  defaultMessage: '<b>{memberName}</b> commented on your note',
                },
                image: {
                  id: 'pages.layout.header.notifications.mappings.myContent.commented.image',
                  defaultMessage: '<b>{memberName}</b> commented on your photo',
                },
                video: {
                  id: 'pages.layout.header.notifications.mappings.myContent.commented.video',
                  defaultMessage: '<b>{memberName}</b> commented on your video',
                },
              }),
              liked: defineMessages({
                album: {
                  id: 'pages.layout.header.notifications.mappings.myContent.liked.album',
                  defaultMessage: '<b>{memberName}</b> liked your album',
                },
                note: {
                  id: 'pages.layout.header.notifications.mappings.myContent.liked.note',
                  defaultMessage: '<b>{memberName}</b> liked your note',
                },
                image: {
                  id: 'pages.layout.header.notifications.mappings.myContent.liked.image',
                  defaultMessage: '<b>{memberName}</b> liked your photo',
                },
                video: {
                  id: 'pages.layout.header.notifications.mappings.myContent.liked.video',
                  defaultMessage: '<b>{memberName}</b> liked your video',
                },
              }),
              ...defineMessages({
                imageFolderSubscribed: {
                  id: 'pages.layout.header.notifications.mappings.myContent.imageFolderSubscribed',
                  defaultMessage:
                    'Your {numCredits, plural, =1 {1 Credit} other {# Credits}} premium album was just purchased by <b>{memberNick}</b>',
                },
                videoSubscribed: {
                  id: 'pages.layout.header.notifications.mappings.myContent.videoSubscribed',
                  defaultMessage:
                    'Your {numCredits, plural, =1 {1 Credit} other {# Credits}} premium video was just purchased by <b>{memberNick}</b>',
                },
              }),
            },
          },
        },
      },
    },
  },
};
