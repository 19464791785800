import type { InternalPartnerConsentRevokedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import messages from './internal-partner.i18n';
import type { Mapping } from './contracts';
import { mountAvatar } from './utils';

const internalPartnerConsentRevoked: Mapping<InternalPartnerConsentRevokedNotification> = () => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.internalPartner.consent.revoked.title],
    description: [messages.pages.layout.header.notifications.mappings.internalPartner.consent.revoked.message],
    avatar: mountAvatar.system(),
    handleClick: () => navigation.promotionToolsExplicitVideosLanding({ openConsentModal: '1' }),
  };
};

export { internalPartnerConsentRevoked };
