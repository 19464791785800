import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        userBadge: {
          content: {
            ...defineMessages({
              switchViewType: {
                id: 'pages.layout.header.userBadge.content.switchViewType',
                defaultMessage: '{product, select, livejasmin {Switch to Studio Center} other {Switch to Agency}}',
              },
              addNewMember: {
                id: 'pages.layout.header.userBadge.content.addNewMember',
                defaultMessage: '{product, select, livejasmin {Add new Model} other {Add new Broadcaster}}',
              },
              logout: {
                id: 'pages.layout.header.userBadge.content.logout',
                defaultMessage: 'Log out',
              },
              logoutModalDescription: {
                id: 'pages.layout.header.userBadge.content.logoutModalDescription',
                defaultMessage: 'Are you sure you want to logout?',
              },
              logoutModalYes: {
                id: 'pages.layout.header.userBadge.content.logoutModalYes',
                defaultMessage: 'Yes',
              },
              logoutModalNo: {
                id: 'pages.layout.header.userBadge.content.logoutModalNo',
                defaultMessage: 'No',
              },
            }),
          },
        },
      },
    },
  },
};
