import type { MessageDescriptor } from 'react-intl';
import type {
  PerformerStatusUpdatedNotification,
  PerformerInteractiveToyDisabledNotification,
  PerformerStoryItemRejectedNotification,
  PerformerBattleEndedNotification,
  PerformerBattleEnabledNotification,
  PerformerBattleDisabledNotification,
  PerformerActionRequestsDisabledNotification,
  PerformerSurveyNotification,
  PerformerShopPaymentOrderPlacedNotification,
  PerformerStoryItemRejectedNotificationContent,
} from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';
import location from 'services/routing/location';
import parse from 'utils/parse';

import messages from './performer.i18n';
import type { Mapping } from './contracts';
import { mountAvatar, closeNotificationPopover, mountMessengerLink, intlLocaleDefaultsRTF } from './utils';

const performerStatusUpdated: Mapping<PerformerStatusUpdatedNotification> = (response) => {
  const { status } = response;
  const statusNormalised = parse.toCamelCase(status);
  const title =
    messages.pages.layout.header.notifications.mappings.performer.status[
      statusNormalised as keyof typeof messages.pages.layout.header.notifications.mappings.performer.status
    ]?.title;
  const message = messages.pages.layout.header.notifications.mappings.performer.status[statusNormalised]
    ?.message as MessageDescriptor;

  if (!title) return null;

  return {
    avatar: mountAvatar.system(),
    title: [title],
    description: message ? [message] : null,
  };
};

const performerInteractiveToyDisabled: Mapping<PerformerInteractiveToyDisabledNotification> = () => {
  return {
    avatar: mountAvatar.interactiveToy(),
    title: [messages.pages.layout.header.notifications.mappings.performer.interactiveToy.disabled.title],
    description: [messages.pages.layout.header.notifications.mappings.performer.interactiveToy.disabled.message],
    handleClick() {
      closeNotificationPopover();
      navigation.contact({ topic: 'other' });
    },
  };
};

const performerVideoCall: Mapping<{ type: 'enabled' | 'disabled' }> = ({ type }) => {
  const title = messages.pages.layout.header.notifications.mappings.performer.videoCall[type]?.title;
  const message = messages.pages.layout.header.notifications.mappings.performer.videoCall[type]?.message;

  if (!title) return null;

  return {
    avatar: mountAvatar.system(),
    title: [title],
    description: message ? [message] : null,
  };
};

const performerMobileLive: Mapping<{ type: 'enabled' | 'disabled' }> = ({ type }) => {
  const title = messages.pages.layout.header.notifications.mappings.performer.mobileLive[type]?.title;
  const message = messages.pages.layout.header.notifications.mappings.performer.mobileLive[type]?.message;

  if (!title) return null;

  return {
    avatar: mountAvatar.system(),
    title: [title],
    description: message ? [message] : null,
  };
};

const getStoryItemRejectedTitle = (
  hasPreview: boolean,
  contentType?: PerformerStoryItemRejectedNotificationContent['storyItem']['contentType']
): MessageDescriptor => {
  if (contentType === 'short') {
    return messages.pages.layout.header.notifications.mappings.performer.story.rejected.shortsTitle;
  }

  return messages.pages.layout.header.notifications.mappings.performer.story.rejected.title;
};

const getStoryItemRejectedDescription = (
  hasPreview: boolean,
  contentType?: PerformerStoryItemRejectedNotificationContent['storyItem']['contentType']
): MessageDescriptor => {
  if (contentType === 'short' || hasPreview) {
    return messages.pages.layout.header.notifications.mappings.performer.story.rejected.message;
  }

  return messages.pages.layout.header.notifications.mappings.performer.story.rejected.deleted;
};

const performerStoryRejected: Mapping<PerformerStoryItemRejectedNotification> = (response) => {
  const {
    content: { media, storyItem } = {},
  } = response;
  const { hasPreview = false } = media!;

  return {
    avatar: mountAvatar.system(),
    title: [getStoryItemRejectedTitle(hasPreview, storyItem?.contentType)],
    description: [getStoryItemRejectedDescription(hasPreview, storyItem?.contentType)],
    preview: hasPreview ? (media?.thumbnail?.contentUrl as string) : true,
    handleClick: !hasPreview
      ? undefined
      : () => {
          closeNotificationPopover();

          if (storyItem?.contentType === 'short') {
            navigation.myContentShorts({ privacy: storyItem?.privacy || 'free', shortsId: storyItem?.id?.toString() });

            return;
          }

          navigation.myContentStories({ storyId: storyItem?.id?.toString() });
        },
  };
};

const performerBattleEnded: Mapping<PerformerBattleEndedNotification> = (notification) => {
  const { content } = notification;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.performer.battle.ended.title,
      { memberTipCount: content?.memberTipCount || 0 },
    ],
    avatar: mountAvatar.battle(),
    handleClick: mountMessengerLink('interaction', null, null),
  };
};

const performerBattleEnabled: Mapping<PerformerBattleEnabledNotification> = () => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.performer.battle.enabled.title],
    description: [messages.pages.layout.header.notifications.mappings.performer.battle.enabled.message],
    avatar: mountAvatar.battle(),
  };
};

const performerBattleDisabled: Mapping<PerformerBattleDisabledNotification> = () => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.performer.battle.disabled.title],
    description: [messages.pages.layout.header.notifications.mappings.performer.battle.disabled.message],
    avatar: mountAvatar.battle(),
    handleClick() {
      closeNotificationPopover();
      navigation.contact({ topic: 'other' });
    },
  };
};

const performerActionRequestDisabled: Mapping<PerformerActionRequestsDisabledNotification> = () => {
  return {
    title: [messages.pages.layout.header.notifications.mappings.performer.actionRequest.disabled.title],
    description: [messages.pages.layout.header.notifications.mappings.performer.actionRequest.disabled.message],
    avatar: mountAvatar.actionRequest(),
    handleClick() {
      closeNotificationPopover();
      navigation.contact({ topic: 'other' });
    },
  };
};

const performerSurvey: Mapping<PerformerSurveyNotification> = (notification) => {
  const { title, body, url } = notification;

  return {
    title,
    description: body,
    avatar: mountAvatar.system(),
    handleClick() {
      location.navigate(url, '_blank');
    },
  };
};

const performerShopPaymentOrderPlaced: Mapping<PerformerShopPaymentOrderPlacedNotification> = (notification) => {
  const {
    member: { memberNick, loyaltyLevel, memberId },
    entanglement: { level, threadId },
  } = notification;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.performer.shopPaymentOrderPlaced.title,
      {
        ...intlLocaleDefaultsRTF,
        memberName: memberNick,
      },
    ],
    description: [messages.pages.layout.header.notifications.mappings.performer.shopPaymentOrderPlaced.message],
    avatar: mountAvatar.loyalty(memberNick, loyaltyLevel),
    handleClick: mountMessengerLink(level, memberId, threadId),
  };
};

export {
  performerStatusUpdated,
  performerInteractiveToyDisabled,
  performerVideoCall,
  performerMobileLive,
  performerStoryRejected,
  performerBattleEnded,
  performerBattleEnabled,
  performerBattleDisabled,
  performerActionRequestDisabled,
  performerSurvey,
  performerShopPaymentOrderPlaced,
};
