import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('header', ...ids);

const testId = Object.freeze({
  header: makeTestId(),
  lhs: makeTestId('lhs'),
  rhs: makeTestId('rhs'),
  hamburgerMenu: makeTestId('hamburger-menu'),
  logoLink: makeTestId('logo-link'),
});

export { testId };
