import type { MessageDescriptor } from 'react-intl';
import type {
  PerformerProfilePictureAcceptedNotification,
  PerformerProfilePictureRejectedNotification,
} from 'services/api/notifications-service/data-contracts';
import application from 'services/application';
import navigation from 'services/navigation';

import messages from './profile-picture.i18n';
import type { Mapping } from './contracts';
import { mountAvatar } from './utils';

type ProfilePictureCategory = 'main' | 'nonExplicit';

const mapProfilePictureCategory = (
  category?: PerformerProfilePictureAcceptedNotification['category']
): ProfilePictureCategory => {
  if (category === 'erotic') return 'nonExplicit';

  return 'main';
};

const getAcceptedTitle = (category?: ProfilePictureCategory, tags?: string[]): MessageDescriptor => {
  if (application.Oranum) return messages.pages.layout.header.notifications.mappings.profilePicture.accepted.title;
  if (category === 'nonExplicit')
    return messages.pages.layout.header.notifications.mappings.profilePicture.accepted.titleNonExplicit;
  if (tags?.length) return messages.pages.layout.header.notifications.mappings.profilePicture.accepted.titleWillingness;

  return messages.pages.layout.header.notifications.mappings.profilePicture.accepted.titleMain;
};

const getRejectedTitle = (category?: ProfilePictureCategory, tags?: string[]): MessageDescriptor => {
  if (application.Oranum) return messages.pages.layout.header.notifications.mappings.profilePicture.rejected.title;
  if (category === 'nonExplicit')
    return messages.pages.layout.header.notifications.mappings.profilePicture.rejected.titleNonExplicit;
  if (tags?.length) return messages.pages.layout.header.notifications.mappings.profilePicture.rejected.titleWillingness;

  return messages.pages.layout.header.notifications.mappings.profilePicture.rejected.titleMain;
};

const handleClick = (category?: ProfilePictureCategory, tags?: string[]): void => {
  if (category === 'nonExplicit') {
    navigation.profile({ page: 'profile-picture', type: 'non-explicit' });

    return;
  }

  if (tags?.length) {
    navigation.profile({ page: 'profile-picture', type: 'willingness' });

    return;
  }

  navigation.profile({ page: 'profile-picture' });
};

const profilePictureAccepted: Mapping<PerformerProfilePictureAcceptedNotification> = (response) => {
  const { media, category, tags } = response;

  const mappedCategory = mapProfilePictureCategory(category);

  return {
    title: [getAcceptedTitle(mappedCategory, tags)],
    description: [messages.pages.layout.header.notifications.mappings.profilePicture.accepted.message],
    avatar: mountAvatar.system(),
    preview: media?.thumbnailUrl || null,
    handleClick: () => handleClick(mappedCategory, tags),
  };
};

const profilePictureRejected: Mapping<PerformerProfilePictureRejectedNotification> = (response) => {
  const { media, category, tags } = response;

  const mappedCategory = mapProfilePictureCategory(category);

  return {
    title: [getRejectedTitle(mappedCategory, tags)],
    description: [messages.pages.layout.header.notifications.mappings.profilePicture.rejected.message],
    avatar: mountAvatar.system(),
    preview: media?.thumbnailUrl || null,
    handleClick: () => handleClick(mappedCategory, tags),
  };
};

export { profilePictureAccepted, profilePictureRejected };
