import React from 'react';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';

import { testId } from '../notifications.settings';

import messages from './content.i18n';
import styles from './content.module.scss';

const Empty: React.FunctionComponent<unknown> = () => {
  const { formatMessage } = useIntl();

  return (
    <Html.div testId={testId.contentEmpty} className={styles.empty}>
      <Html.p>
        <Icon name={IconList.notificationsSolid} testId={testId.contentEmptyIcon} size={32} className={styles.icon} />
      </Html.p>
      <Html.p typography="body2">{formatMessage(messages.pages.layout.header.notifications.content.empty)}</Html.p>
    </Html.div>
  );
};

export default Empty;
