import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('header-user-badge', ...ids);

const testId = Object.freeze({
  userBadge: makeTestId(),
  userBadgeIcon: makeTestId('icon'),
});

export { testId };
