import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('header-user-badge-content', ...ids);

const testId = Object.freeze({
  content: makeTestId(),
  email: makeTestId('email'),
  logout: makeTestId('logout'),
  logoutModal: makeTestId('logout-modal'),
  switchAccount: makeTestId('switch-account'),
  addNewModel: makeTestId('add-new-model'),
});

export { testId };
