import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            referral: {
              consent: {
                ...defineMessages({
                  revoked: {
                    id: 'pages.layout.header.notifications.mappings.referral.consent.revoked',
                    defaultMessage:
                      'Your referral fee was revoked by the support team. Your income share has returned to {shareValue} again',
                  },
                }),
              },
              connection: {
                ...defineMessages({
                  created: {
                    id: 'pages.layout.header.notifications.mappings.referral.connection.created',
                    defaultMessage:
                      "Good news! {name} just registered with your referral link. For a whole year, you'll earn a {commission} commission on their earnings",
                  },
                  cut: {
                    id: 'pages.layout.header.notifications.mappings.referral.connection.cut',
                    defaultMessage:
                      'Support team has removed {name} from your referrals. For more information, please contact the Support team',
                  },
                }),
              },
            },
          },
        },
      },
    },
  },
};
