import React from 'react';
import { map, distinctUntilChanged } from 'rxjs';
import useReadonlyObservable from 'enhancers/use-readonly-observable';

import notificationsStore from '../notifications.store';
import type { NotificationData } from '../notifications.store';

import UI, { type ContentUIElement } from './content.ui';

type ContentElement = Pick<ContentUIElement, 'visible'>;

const Content: React.FunctionComponent<ContentElement> = (props) => {
  const { visible } = props;
  const [empty, setEmpty] = React.useState(false);
  const [newData, setNewData] = React.useState<NotificationData[]>([]);
  const [todayData, setTodayData] = React.useState<NotificationData[]>([]);
  const [earlierData, setEarlierData] = React.useState<NotificationData[]>([]);
  const [hasMorePages] = useReadonlyObservable(
    notificationsStore.onChange$.pipe(
      map((store) => store.hasMorePages),
      distinctUntilChanged()
    ),
    notificationsStore.loading
  );
  const [unfetched] = useReadonlyObservable(
    notificationsStore.onChange$.pipe(
      map((store) => store.unfetched),
      distinctUntilChanged()
    ),
    notificationsStore.data.unfetched
  );

  React.useEffect(() => {
    const newSubscription = notificationsStore.onNotificationGroup$('new').subscribe(setNewData);
    const todaySubscription = notificationsStore.onNotificationGroup$('today').subscribe(setTodayData);
    const earlierSubscription = notificationsStore.onNotificationGroup$('earlier').subscribe(setEarlierData);
    const subscription = notificationsStore.onChange$.pipe(map((data) => !data.history.length)).subscribe(setEmpty);

    return () => {
      subscription.unsubscribe();
      newSubscription.unsubscribe();
      todaySubscription.unsubscribe();
      earlierSubscription.unsubscribe();
    };
  }, []);

  const handleOnLoadNextPage = React.useCallback(() => {
    notificationsStore.loadNextPage();
  }, []);

  return (
    <UI
      visible={visible}
      empty={empty}
      loading={unfetched}
      new={newData}
      today={todayData}
      earlier={earlierData}
      hasMorePages={hasMorePages}
      loadNextPage={handleOnLoadNextPage}
    />
  );
};

export default Content;
