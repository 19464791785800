import type { LoyalfansRegistrationStatusUpdatedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import messages from './loyalfans.i18n';
import type { Mapping } from './contracts';
import { mountAvatar, closeNotificationPopover } from './utils';

const getRegistrationStatusName = (
  registrationStatus: LoyalfansRegistrationStatusUpdatedNotification['loyalfansRegistrationStatus']
): 'underApproval' | 'successful' | 'incomplete' => {
  switch (registrationStatus) {
    case 'successful':
      return 'successful';
    case 'under_approval':
      return 'underApproval';
    default:
      return 'incomplete';
  }
};

const loyalfansRegistrationStatusUpdated: Mapping<LoyalfansRegistrationStatusUpdatedNotification> = (response) => {
  const { loyalfansRegistrationStatus } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.loyalfans[
        getRegistrationStatusName(loyalfansRegistrationStatus)
      ].title,
    ],
    description: [
      messages.pages.layout.header.notifications.mappings.loyalfans[
        getRegistrationStatusName(loyalfansRegistrationStatus)
      ].message,
    ],
    avatar: mountAvatar.system(),
    handleClick: () => {
      closeNotificationPopover();
      navigation.earnMore({ page: 'loyalfans' });
    },
  };
};

export { loyalfansRegistrationStatusUpdated };
