import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            instantPayouts: {
              updated: {
                title: {
                  ...defineMessages({
                    disabled: {
                      id: 'pages.layout.header.notifications.mappings.instantPayouts.updated.title.disabled',
                      defaultMessage:
                        'Instant Payouts were disabled by Support. Please contact our Support team if you have any questions.',
                    },
                    enabled: {
                      id: 'pages.layout.header.notifications.mappings.instantPayouts.updated.title.enabled',
                      defaultMessage:
                        'Instant Payouts were enabled by Support. Please contact our Support team if you have any questions.',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
