import React from 'react';
import { map, fromEvent, throttleTime, filter, of, merge } from 'rxjs';
import type { IntlFormatters } from 'react-intl';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import RelativeTime from 'components/relative-time';
import is from 'utils/is';
import { noopFn } from 'utils/noop';

import type { NotificationData } from '../notifications.store';
import { testId, scrollThreshold } from '../notifications.settings';

import NotificationMessage from './notification-message';
import NotificationAvatar from './notification-avatar';
import Empty from './empty';
import Loading from './loading';
import messages from './content.i18n';
import styles from './content.module.scss';

const fakeNotification: NotificationData = {
  avatar: {
    initials: 'E',
    loyalty: 'Crush',
    type: 'loyalty',
  },
  description: null,
  handleClick: noopFn,
  id: '1',
  preview: null,
  read: false,
  time: 1664795127000,
  title: [{ id: 'title', defaultMessage: 'Title Title Title Title' }],
  today: true,
  unfetched: true,
};

interface ContentUIElement {
  visible: boolean;
  empty: boolean;
  loading: number;
  hasMorePages: boolean;
  new: Array<NotificationData>;
  today: Array<NotificationData>;
  earlier: Array<NotificationData>;
  loadNextPage: () => void;
}

const isFormatMessageParams = (
  msg: Parameters<IntlFormatters<string>['formatMessage']> | string | null
): msg is Parameters<IntlFormatters<string>['formatMessage']> => is.array(msg) && Boolean(msg[0]);

const Content: React.FunctionComponent<ContentUIElement> = (props) => {
  const { visible, empty, loading, hasMorePages, new: newNotifications, today, earlier, loadNextPage } = props;
  const { formatMessage } = useIntl();
  const contentBody = React.useRef<HTMLDivElement>(null);

  const shimmerData = React.useMemo(() => {
    if (loading <= 0) return [];

    return new Array<NotificationData>(loading).fill(fakeNotification).map((data, idx) => {
      return { ...data, id: `shimmer-${idx}` };
    });
  }, [loading]);

  React.useEffect(() => {
    if (!contentBody.current) return;

    const scroll$ = fromEvent(contentBody.current, 'scroll');

    const subscription = merge(scroll$, of(visible).pipe(filter(Boolean)))
      .pipe(
        map(noopFn),
        throttleTime(150, undefined, { leading: false, trailing: true }),
        filter(() => {
          const target = contentBody.current!;

          const maximumHeight = target.scrollHeight;
          const scrolledAmount = target.offsetHeight + target.scrollTop;

          return scrolledAmount > maximumHeight - scrollThreshold;
        })
      )
      .subscribe(() => {
        loadNextPage();
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [visible, loadNextPage]);

  const renderBody = React.useCallback(
    (items: NotificationData[], bodyTitle: string, bodyTestId: string): React.ReactNode => {
      if (!items.length) return null;

      return (
        <React.Fragment>
          <Html.div className={[styles.title, 'px-4', 'py-2']}>
            <Html.span typography="caption" className={['text-uppercase', 'fw-bold']}>
              {bodyTitle}
            </Html.span>
          </Html.div>
          <Html.div testId={bodyTestId}>
            {items.map(
              ({ id, avatar, time, title, description, read, preview, unfetched: isUnFetched, handleClick }) => (
                <NotificationMessage
                  avatar={
                    <NotificationAvatar
                      initials={avatar.initials}
                      loyalty={avatar.loyalty}
                      type={avatar.type}
                      unread={!read}
                      testId={testId.avatar}
                    />
                  }
                  description={isFormatMessageParams(description) ? formatMessage(...description) : description}
                  handleClick={handleClick}
                  preview={preview}
                  time={<RelativeTime highlight={!read}>{time}</RelativeTime>}
                  title={isFormatMessageParams(title) ? formatMessage(...title) : title}
                  key={id}
                  testId={testId.message}
                  loading={!!isUnFetched}
                />
              )
            )}
          </Html.div>
        </React.Fragment>
      );
    },
    [formatMessage]
  );

  const newBody = React.useMemo(() => {
    return renderBody(
      [...shimmerData, ...newNotifications],
      formatMessage(messages.pages.layout.header.notifications.content.new),
      testId.new
    );
  }, [renderBody, shimmerData, newNotifications, formatMessage]);

  const todayBody = React.useMemo(
    () => renderBody(today, formatMessage(messages.pages.layout.header.notifications.content.today), testId.today),
    [renderBody, today, formatMessage]
  );

  const earlierBody = React.useMemo(
    () =>
      renderBody(earlier, formatMessage(messages.pages.layout.header.notifications.content.earlier), testId.earlier),
    [earlier, formatMessage, renderBody]
  );

  return (
    <Html.div className={['text-center rounded-md-1', styles.content]}>
      <Html.div testId={testId.contentScroll} className={styles.wrapper} ref={contentBody}>
        {empty && !loading && <Empty />}
        {!empty && (
          <Html.div testId={testId.content} className={['text-start']}>
            {newBody}
            {todayBody}
            {earlierBody}
          </Html.div>
        )}
        {hasMorePages && <Loading minimal={!empty} />}
      </Html.div>
    </Html.div>
  );
};

export type { ContentUIElement };
export default Content;
