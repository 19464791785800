import type {
  FavouriteAddedNotification,
  MyContentCommentedNotification,
  MyContentImageFolderSubscribedNotification,
  MyContentLikedNotification,
  MyContentVideoSubscribedNotification,
  NotificationListResponse,
  PerformerProfilePictureAcceptedNotification,
  PerformerProfilePictureRejectedNotification,
  PerformerStatusUpdatedNotification,
  PerformerInteractiveToyDisabledNotification,
  FanClubMemberSubscribedNotification,
  FanClubMemberResubscribedNotification,
  FanClubMemberUnsubscribedNotification,
  InstantPayoutsUpdatedNotification,
  PerformerStoryItemRejectedNotification,
  PerformerBattleEndedNotification,
  PerformerBattleEnabledNotification,
  PerformerBattleDisabledNotification,
  PerformerChannelContentRejectedNotification,
  PerformerActionRequestsDisabledNotification,
  PerformerSurveyNotification,
  MyStorySubscriptionCreatedNotification,
  PromotionContentAcceptedNotification,
  PromotionContentRejectedNotification,
  PerformerPromotionTeaserParticipantConfirmationRequiredNotification,
  PerformerPromotionTeaserParticipantAddedNotification,
  PerformerReferralConnectionCreatedNotification,
  PerformerReferralConnectionCutNotification,
  PerformerReferralConsentRevokedNotification,
  CompetitorRewardedNotification,
  ExcludedAwardGameNotification,
  PerformerShopPaymentOrderPlacedNotification,
  LoyalfansRegistrationStatusUpdatedNotification,
  DirectPayoutUpdatedNotification,
  PerformerPromotionTeaserAcceptedNotification,
  PerformerPromotionTeaserRejectedNotification,
  InternalPartnerConsentRevokedNotification,
} from 'services/api/notifications-service/data-contracts';

import * as mappings from './mappings';
import { NotificationEvent } from './contracts';
import type { PartialNotification, PartialNotificationWithTitle } from './mappings/contracts';

const getMappedNotificationPartial = (
  response: Exclude<NotificationListResponse['data'], undefined>[number]
): PartialNotificationWithTitle | null => {
  switch (response.type) {
    case NotificationEvent.CompetitorRewarded:
      return mappings.competitorRewarded(response as CompetitorRewardedNotification);

    case NotificationEvent.ExcludedAwardGame:
      return mappings.excludedAwardGame(response as ExcludedAwardGameNotification);

    case NotificationEvent.FavouriteAdded:
      return mappings.favouriteAdded(response as FavouriteAddedNotification);

    case NotificationEvent.FanclubMemberSubscribed:
      return mappings.fanClubMemberSubscribed(response as FanClubMemberSubscribedNotification);

    case NotificationEvent.FanclubMemberResubscribed:
      return mappings.fanClubMemberResubscribed(response as FanClubMemberResubscribedNotification);

    case NotificationEvent.FanclubMemberUnsubscribed:
      return mappings.fanClubMemberUnsubscribed(response as FanClubMemberUnsubscribedNotification);

    case NotificationEvent.InstantPayoutsUpdated:
      return mappings.instantPayoutsUpdated(response as InstantPayoutsUpdatedNotification);

    case NotificationEvent.LoyalfansRegistrationStatusUpdated:
      return mappings.loyalfansRegistrationStatusUpdated(response as LoyalfansRegistrationStatusUpdatedNotification);

    case NotificationEvent.MyContentCommented:
      return mappings.myContentCommented(response as MyContentCommentedNotification);

    case NotificationEvent.MyContentLiked:
      return mappings.myContentLiked(response as MyContentLikedNotification);

    case NotificationEvent.MyContentImageFolderSubscribed:
      return mappings.myContentImageFolderSubscribed(response as MyContentImageFolderSubscribedNotification);

    case NotificationEvent.MyContentVideoSubscribed:
      return mappings.myContentVideoSubscribed(response as MyContentVideoSubscribedNotification);

    case NotificationEvent.MyStoryStorySubscriptionCreated:
      return mappings.myStoryNewSubscription(response as MyStorySubscriptionCreatedNotification);

    case NotificationEvent.ProfilePictureAccepted:
      return mappings.profilePictureAccepted(response as PerformerProfilePictureAcceptedNotification);

    case NotificationEvent.ProfilePictureRejected:
      return mappings.profilePictureRejected(response as PerformerProfilePictureRejectedNotification);

    case NotificationEvent.PerformerStatusUpdated:
      return mappings.performerStatusUpdated(response as PerformerStatusUpdatedNotification);

    case NotificationEvent.PerformerChannelContentRejected:
      return mappings.channelContentRejected(response as PerformerChannelContentRejectedNotification);

    case NotificationEvent.PerformerInteractiveToyDisabled:
      return mappings.performerInteractiveToyDisabled(response as PerformerInteractiveToyDisabledNotification);

    case NotificationEvent.PerformerVideoCallEnabled:
      return mappings.performerVideoCall({ type: 'enabled' });

    case NotificationEvent.PerformerVideoCallDisabled:
      return mappings.performerVideoCall({ type: 'disabled' });

    case NotificationEvent.PerformerMobileLiveEnabled:
      return mappings.performerMobileLive({ type: 'enabled' });

    case NotificationEvent.PerformerMobileLiveDisabled:
      return mappings.performerMobileLive({ type: 'disabled' });

    case NotificationEvent.PerformerStoryItemRejected:
      return mappings.performerStoryRejected(response as PerformerStoryItemRejectedNotification);

    case NotificationEvent.PerformerBattleEnded:
      return mappings.performerBattleEnded(response as PerformerBattleEndedNotification);

    case NotificationEvent.PerformerBattleEnabled:
      return mappings.performerBattleEnabled(response as PerformerBattleEnabledNotification);

    case NotificationEvent.PerformerBattleDisabled:
      return mappings.performerBattleDisabled(response as PerformerBattleDisabledNotification);

    case NotificationEvent.PerformerActionRequestsDisabled:
      return mappings.performerActionRequestDisabled(response as PerformerActionRequestsDisabledNotification);

    case NotificationEvent.PerformerSurveyNotification:
      return mappings.performerSurvey(response as PerformerSurveyNotification);

    case NotificationEvent.PerformerShopPaymentOrderPlaced:
      return mappings.performerShopPaymentOrderPlaced(response as PerformerShopPaymentOrderPlacedNotification);

    case NotificationEvent.PromotoolsPromotionContentStatusAccepted:
      return mappings.promotoolsAccepted(response as PromotionContentAcceptedNotification);

    case NotificationEvent.PromotoolsPromotionContentStatusRejected:
      return mappings.promotoolsRejected(response as PromotionContentRejectedNotification);

    case NotificationEvent.PerformerPerformerReferralConsentRevoked:
      return mappings.referralConsentRevoked(response as PerformerReferralConsentRevokedNotification);

    case NotificationEvent.PerformerPerformerReferralConnectionCreated:
      return mappings.referralConnectionCreated(response as PerformerReferralConnectionCreatedNotification);

    case NotificationEvent.PerformerPerformerReferralConnectionCut:
      return mappings.referralConnectionCut(response as PerformerReferralConnectionCutNotification);

    case NotificationEvent.PerformerPromotionTeaserStatusAccepted:
      return mappings.promotionTeaserAccepted(response as PerformerPromotionTeaserAcceptedNotification);

    case NotificationEvent.PerformerPromotionTeaserStatusRejected:
      return mappings.promotionTeaserRejected(response as PerformerPromotionTeaserRejectedNotification);

    case NotificationEvent.PerformerPromotionTeaserParticipantConfirmationRequired:
      return mappings.promotionTeaserParticipantConfirmationRequired(
        response as PerformerPromotionTeaserParticipantConfirmationRequiredNotification
      );

    case NotificationEvent.PerformerPromotionTeaserParticipantAdded:
      return mappings.promotionTeaserParticipantAdded(response as PerformerPromotionTeaserParticipantAddedNotification);

    case NotificationEvent.DirectPayoutPending:
      return mappings.directPayoutPending(response as DirectPayoutUpdatedNotification);

    case NotificationEvent.DirectPayoutApproved:
      return mappings.directPayoutApproved(response as DirectPayoutUpdatedNotification);

    case NotificationEvent.DirectPayoutDeleted:
      return mappings.directPayoutDeleted(response as DirectPayoutUpdatedNotification);

    case NotificationEvent.DirectPayoutDisabledBySupport:
      return mappings.directPayoutDeleted(response as DirectPayoutUpdatedNotification);

    case NotificationEvent.InternalPartnerConsentRevoked:
      return mappings.internalPartnerConsentRevoked(response as InternalPartnerConsentRevokedNotification);

    default:
      return null;
  }
};

const mapNotificationType = (
  response: Exclude<NotificationListResponse['data'], undefined>[number]
): PartialNotification => {
  const defaults: Omit<Exclude<PartialNotification, null>, 'title'> = {
    avatar: {
      type: 'icon-system',
      loyalty: undefined,
      initials: undefined,
    },
    preview: null,
    description: null,
  };

  const mapped = getMappedNotificationPartial(response);

  return mapped ? { ...defaults, ...mapped } : null;
};

export { mapNotificationType };
