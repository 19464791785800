import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            promotion: {
              accepted: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.promotion.accepted.title',
                    defaultMessage: 'Your explicit video was accepted',
                  },
                }),
              },
              rejected: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.promotion.rejected.title',
                    defaultMessage: 'Your explicit video was rejected',
                  },
                  description: {
                    id: 'pages.layout.header.notifications.mappings.promotion.rejected.description',
                    defaultMessage: 'Click to find out more',
                  },
                }),
              },
              participantConfirmationRequired: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.promotion.participantConfirmationRequired.title',
                    defaultMessage: 'You were tagged in an Explicit Video by {performerName}.',
                  },
                  description: {
                    id: 'pages.layout.header.notifications.mappings.promotion.participantConfirmationRequired.description',
                    defaultMessage: 'Click here to give your consent for promotion.',
                  },
                }),
              },
              participantAdded: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.promotion.participantAdded.title',
                    defaultMessage: 'You were tagged in an Explicit Video by {performerName}.',
                  },
                }),
              },
            },
          },
        },
      },
    },
  },
};
