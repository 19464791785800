import React from 'react';
import type { IntlFormatters } from 'react-intl';
import Html from 'components/html';
import type { LoyaltyLevel } from 'components/loyalty-mask/contracts';
import navigation from 'services/navigation';
import is from 'utils/is';

import type { NotificationAvatar } from '../notifications.store';

const intlLocaleDefaultsRTF: Parameters<IntlFormatters<string>['formatMessage']>[1] = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  b: (message) => <Html.strong>{message}</Html.strong>,
};

const closeNotificationPopover = (): void => document.body.click();

const mountMessengerLink =
  (
    level: 'interaction' | 'thread' | 'none' | 'spending',
    memberId: number | null,
    threadId: number | null
  ): (() => void) =>
  () => {
    closeNotificationPopover();

    if (level === 'interaction') {
      navigation.messenger({ page: 'recommended', threadId: memberId?.toString() });

      return;
    }

    navigation.messenger({ page: 'current-chats', threadId: threadId?.toString() ?? undefined });
  };

const mountAvatar = {
  loyalty: (memberNick: string | null, loyaltyLevel: 'Not in loyalty' | LoyaltyLevel | null): NotificationAvatar => ({
    initials: memberNick ?? undefined,
    loyalty: is.nullish(loyaltyLevel) || loyaltyLevel === 'Not in loyalty' ? undefined : loyaltyLevel,
    type: 'loyalty',
  }),
  system: (): NotificationAvatar => ({
    type: 'icon-system',
    loyalty: undefined,
    initials: undefined,
  }),
  interactiveToy: (): NotificationAvatar => ({
    type: 'icon-interactive-toy',
    loyalty: undefined,
    initials: undefined,
  }),
  battle: (): NotificationAvatar => ({
    type: 'icon-battle',
    loyalty: undefined,
    initials: undefined,
  }),
  actionRequest: (): NotificationAvatar => ({
    type: 'icon-action-request',
    loyalty: undefined,
    initials: undefined,
  }),
};

export { intlLocaleDefaultsRTF, closeNotificationPopover, mountMessengerLink, mountAvatar };
