import type { MessageDescriptor } from 'react-intl';
import type {
  PromotionContentAcceptedNotification,
  PromotionContentRejectedNotification,
} from 'services/api/notifications-service/data-contracts';
import application from 'services/application';
import navigation from 'services/navigation';
import performer from 'store/performer';

import messages from './promotools.i18n';
import type { Mapping } from './contracts';
import { closeNotificationPopover, mountAvatar } from './utils';

const getTitle = (
  status: 'accepted' | 'rejected',
  type: PromotionContentAcceptedNotification['contentType'],
  tags: PromotionContentAcceptedNotification['tags']
): MessageDescriptor | null => {
  if (type === 'video-ads' && application.Oranum) {
    return messages.pages.layout.header.notifications.mappings.promotools.status[status].videoAds;
  }

  if (tags.includes('portrait')) {
    return messages.pages.layout.header.notifications.mappings.promotools.status[status].portrait;
  }

  if (tags.includes('landscape')) {
    return messages.pages.layout.header.notifications.mappings.promotools.status[status].landscape;
  }

  return messages.pages.layout.header.notifications.mappings.promotools.status[status].videoCall;
};

const navigate = (tags: PromotionContentAcceptedNotification['tags']): void => {
  closeNotificationPopover();

  if (tags.includes('portrait')) {
    if (application.Oranum) {
      navigation.promotionToolsVideoAds({ type: 'portrait' });

      return;
    }

    if (performer.hasCelebrityCategory() || performer.hasHotFlirtCategory()) {
      navigation.promotionToolsPromoTeaser({ type: 'mobile' });
    } else {
      navigation.promotionToolsEroticTeaser({ type: 'mobile' });
    }

    return;
  }

  if (tags.includes('landscape')) {
    if (application.Oranum) {
      navigation.promotionToolsVideoAds({ type: 'landscape' });

      return;
    }

    if (performer.hasCelebrityCategory() || performer.hasHotFlirtCategory()) {
      navigation.promotionToolsPromoTeaser({ type: 'desktop' });
    } else {
      navigation.promotionToolsEroticTeaser({ type: 'desktop' });
    }

    return;
  }

  navigation.promotionToolsVideoCall();
};

const promotoolsAccepted: Mapping<PromotionContentAcceptedNotification> = (notification) => {
  const { contentType, tags } = notification;

  const title = getTitle('accepted', contentType, tags);

  if (!title) return null;

  return {
    title: [title],
    avatar: mountAvatar.system(),
    handleClick() {
      navigate(tags);
    },
  };
};

const promotoolsRejected: Mapping<PromotionContentRejectedNotification> = (notification) => {
  const { contentType, tags } = notification;

  const title = getTitle('rejected', contentType, tags);

  if (!title) return null;

  return {
    title: [title],
    avatar: mountAvatar.system(),
    handleClick() {
      navigate(tags);
    },
  };
};

export { promotoolsAccepted, promotoolsRejected };
