import parse from 'utils/parse';

const makeTestId = (...ids: string[]): string => parse.toTestId('header-notifications', ...ids);

const testId = Object.freeze({
  notifications: makeTestId(),
  badge: makeTestId('badge'),
  badgeCounter: makeTestId('badge-counter'),
  icon: makeTestId('badge-icon'),
  contentEmpty: makeTestId('content-empty'),
  contentEmptyIcon: makeTestId('content-empty-icon'),
  contentLoading: makeTestId('content-loading'),
  content: makeTestId('content'),
  contentScroll: makeTestId('content-scroll'),
  new: makeTestId('new'),
  today: makeTestId('today'),
  earlier: makeTestId('earlier'),
  loading: makeTestId('loading'),
  avatar: makeTestId('avatar'),
  message: makeTestId('message'),
});

const scrollThreshold = 250;

export { testId, scrollThreshold };
