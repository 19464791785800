import { defineTracking } from 'services/google-analytics';

const category = 'header';

export default {
  logo: {
    ...defineTracking({
      click: {
        category,
        action: 'logo click',
        label: 'layout header logo clicked',
        description: 'This event triggered when user clicks on header logo.',
      },
    }),
  },
  hamburger: {
    ...defineTracking({
      toggle: {
        category,
        action: 'hamburger menu toggle',
        label: 'Toggle Navigation Menu {status}',
        description:
          'This event triggered when user clicks on header hamburger menu. ' +
          '{status} represents the Navigation Menu open state, it can be either "open" or "closed". ' +
          'Note this behaviour is only for tablet size viewport down.',
      },
    }),
  },
};
