import type {
  CompetitorRewardedNotification,
  ExcludedAwardGameNotification,
} from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';
import parse from 'utils/parse';

import messages from './competitor.i18n';
import type { Mapping } from './contracts';
import { closeNotificationPopover, mountAvatar } from './utils';

const competitorRewarded: Mapping<CompetitorRewardedNotification> = (notification) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { gameType, position, creditReward } = notification;

  const title =
    messages.pages.layout.header.notifications.mappings.competitor.rewarded.title[
      parse.toCamelCase(
        gameType as string
      ) as keyof typeof messages.pages.layout.header.notifications.mappings.competitor.rewarded.title
    ];

  if (!title) return null;

  return {
    title: [title],
    description: [
      creditReward
        ? messages.pages.layout.header.notifications.mappings.competitor.rewarded.messageWithCredits
        : messages.pages.layout.header.notifications.mappings.competitor.rewarded.messageWithoutCredits,
      {
        position,
        creditReward,
      },
    ],
    avatar: mountAvatar.system(),
    handleClick: () => {
      closeNotificationPopover();
      navigation.awards();
    },
  };
};

const excludedAwardGame: Mapping<ExcludedAwardGameNotification> = (notification) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { gameType } = notification;

  const title =
    messages.pages.layout.header.notifications.mappings.competitor.excluded.title[
      parse.toCamelCase(
        gameType as string
      ) as keyof typeof messages.pages.layout.header.notifications.mappings.competitor.rewarded.title
    ];

  if (!title) return null;

  return {
    title: [title],
    description: [messages.pages.layout.header.notifications.mappings.competitor.excluded.message],
    avatar: mountAvatar.system(),
    handleClick: () => {
      closeNotificationPopover();
      navigation.contact({ topic: 'other' });
    },
  };
};

export { competitorRewarded, excludedAwardGame };
