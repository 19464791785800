import { defineTracking } from 'services/google-analytics';

const category = 'header - notifications';

export default {
  ...defineTracking({
    toggle: {
      category,
      action: 'toggle',
      label: '{status} content',
      description:
        'This event triggered when user toggles header notification. ' +
        '{status} represents its status, it can be either "open" or "close"',
    },
    refresh: {
      category,
      action: 'refresh',
      label: 'refresh content list',
      nonInteraction: true,
      description:
        'This an automatic event that is triggered when user open header notification, ' +
        'its purpose is to serve up-to-date content.',
    },
    markAllAsRead: {
      category,
      action: 'mark all as read',
      label: 'mark all unread notifications as read',
      nonInteraction: true,
      description:
        'This an automatic event that is triggered when user closes header notification, ' +
        'its purpose is to mark all mark notifications as read.',
    },
  }),
};
