import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          content: defineMessages({
            loading: {
              id: 'pages.layout.header.notifications.content.loading',
              defaultMessage: 'Loading notifications...',
            },
            empty: {
              id: 'pages.layout.header.notifications.content.empty',
              defaultMessage: 'You have no notifications yet',
            },
            new: {
              id: 'pages.layout.header.notifications.content.new',
              defaultMessage: 'New',
            },
            today: {
              id: 'pages.layout.header.notifications.content.today',
              defaultMessage: 'Today',
            },
            earlier: {
              id: 'pages.layout.header.notifications.content.earlier',
              defaultMessage: 'Earlier',
            },
          }),
        },
      },
    },
  },
};
