import React from 'react';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';
import application from 'services/application';
import Modrawer from 'components/modrawer';
import Button from 'components/button';

import messages from './content.i18n';
import { testId } from './content.settings';
import styles from './content.module.scss';

interface UserBadgeContent {
  email: string | undefined;
  showSwitchToStudioButton: boolean;
  showAddNewModelButton: boolean;
  onAddNewModelRequest: () => void;
  onSwitchToStudioRequest: () => void;
  onLogoutRequest: () => void;
}

const Content: React.FunctionComponent<UserBadgeContent> = (props) => {
  const {
    email,
    showSwitchToStudioButton,
    showAddNewModelButton,
    onAddNewModelRequest,
    onSwitchToStudioRequest,
    onLogoutRequest,
  } = props;
  const { formatMessage } = useIntl();
  const [showLogout, toggleLogoutModal] = React.useState<boolean>();

  const showLogoutModal = React.useCallback((): void => toggleLogoutModal(true), []);
  const hideLogoutModal = React.useCallback((): void => toggleLogoutModal(false), []);

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = React.useCallback((e) => e.stopPropagation(), []);

  return (
    <React.Fragment>
      <Html.div className={styles.content} testId={testId.content} onClick={stopPropagation}>
        <Html.div className={styles.wrapper}>
          {email && (
            <Html.div testId={testId.email} className={[styles.email, 'p-6 mb-0']} typography="body2">
              {email}
            </Html.div>
          )}
          {showSwitchToStudioButton && (
            <Html.div className="px-6 mb-1">
              <Button testId={testId.switchAccount} className="w-100" size="s" onClick={onSwitchToStudioRequest}>
                {formatMessage(messages.pages.layout.header.userBadge.content.switchViewType, {
                  product: application.current,
                })}
              </Button>
            </Html.div>
          )}
          {showAddNewModelButton && (
            <Html.div className="px-6 mb-1">
              <Button testId={testId.addNewModel} className="w-100" size="s" onClick={onAddNewModelRequest}>
                {formatMessage(messages.pages.layout.header.userBadge.content.addNewMember, {
                  product: application.current,
                })}
              </Button>
            </Html.div>
          )}
          <Html.ul className={styles.menu}>
            <Html.li
              testId={testId.logout}
              className={['py-4 px-6 mb-0', styles.menuItem]}
              typography="body2"
              fontWeight="bold"
              onClick={showLogoutModal}
            >
              <Icon name={IconList.logoutOutline} size={24} className={styles.icon} />
              {formatMessage(messages.pages.layout.header.userBadge.content.logout)}
            </Html.li>
          </Html.ul>
        </Html.div>
      </Html.div>
      {showLogout && (
        <Modrawer testId={testId.logoutModal} className="flex-wrap" onClose={hideLogoutModal}>
          <React.Fragment>
            <Html.h4 className="text-center mb-8" typography="title1" fontWeight="bold">
              {formatMessage(messages.pages.layout.header.userBadge.content.logoutModalDescription)}
            </Html.h4>
            <Html.div className="w-100 text-center mb-1">
              <Button onClick={onLogoutRequest} className={['me-sm-2', 'w-100', 'w-sm-auto']} withFocus>
                {formatMessage(messages.pages.layout.header.userBadge.content.logoutModalYes)}
              </Button>
              <Button
                onClick={hideLogoutModal}
                className={['w-100', 'w-sm-auto', 'mt-2', 'mt-sm-0']}
                variant={application.LiveJasmin ? 'secondary1' : 'secondary2'}
              >
                {formatMessage(messages.pages.layout.header.userBadge.content.logoutModalNo)}
              </Button>
            </Html.div>
          </React.Fragment>
        </Modrawer>
      )}
    </React.Fragment>
  );
};

export type { UserBadgeContent };
export default Content;
