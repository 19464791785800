import React from 'react';
import { distinctUntilChanged } from 'rxjs';
import { sendTracking } from 'services/google-analytics';
import useReadonlyObservable from 'enhancers/use-readonly-observable';

import UI, { type NotificationsElement } from './notifications.ui';
import notifications from './notifications.store';
import tracking from './notifications.tracking';

const Notifications: React.FunctionComponent = () => {
  const [{ enabled, unread }] = useReadonlyObservable(
    notifications.onChange$.pipe(
      distinctUntilChanged((prev, next) => prev.enabled === next.enabled && prev.unread === next.unread)
    ),
    notifications.data
  );

  const handleContentToggle: NotificationsElement['onContentToggle'] = React.useCallback((open) => {
    sendTracking(tracking.toggle, { status: open ? 'open' : 'close' });
  }, []);

  const handleOnRefresh: NotificationsElement['onRefreshRequest'] = React.useCallback(() => {
    notifications.refresh();

    sendTracking(tracking.refresh);
  }, []);

  const handleOnMarkAllAsRead: NotificationsElement['onMarkAllAsReadRequest'] = React.useCallback(() => {
    void notifications.markAllAsRead();

    sendTracking(tracking.markAllAsRead);
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <UI
      unread={unread}
      onContentToggle={handleContentToggle}
      onRefreshRequest={handleOnRefresh}
      onMarkAllAsReadRequest={handleOnMarkAllAsRead}
    />
  );
};

export default React.memo(Notifications);
