import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            internalPartner: {
              consent: {
                revoked: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.internalPartner.consent.revoked.title',
                      defaultMessage: 'Support has revoked your Explicit Videos consent.',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.internalPartner.consent.revoked.message',
                      defaultMessage: 'You can give your consent again at any time on the Explicit Videos page.',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
