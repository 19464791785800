import { defineMessages } from 'react-intl';

const links = {
  ...defineMessages({
    logo: {
      id: 'pages.layout.header.links.logo',
      defaultMessage: 'Go to main page.',
    },
  }),
};

const aria = {
  ...defineMessages({
    hamburgerLabel: {
      id: 'pages.layout.header.aria.hamburgerLabel',
      defaultMessage: 'Toggle Navigation Menu',
    },
  }),
};

export default {
  pages: {
    layout: {
      header: {
        aria,
        links,
      },
    },
  },
};
