import React from 'react';
import useNavigation from 'enhancers/use-navigation';
import Link from 'components/link';
import Icon, { IconList } from 'components/icon';

import styles from './account-settings.module.scss';
import { testId } from './account-settings.settings';

interface AccountSettingsElement {
  active: boolean;
}

const AccountSettings: React.FunctionComponent<AccountSettingsElement> = (props) => {
  const { active } = props;
  const [, , link] = useNavigation();

  return (
    // eslint-disable-next-line css-modules/no-undef-class
    <Link testId={testId.accountSettings} href={link.settings()} className={styles.settings}>
      <Icon name={IconList.settingsOutline} className={[styles.icon, active && styles.active]} />
    </Link>
  );
};

export type { AccountSettingsElement };
export default AccountSettings;
