import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            promotools: {
              status: {
                accepted: {
                  ...defineMessages({
                    portrait: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.accepted.portrait',
                      defaultMessage: 'Your portrait teaser video was accepted',
                    },
                    landscape: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.accepted.landscape',
                      defaultMessage: 'Your landscape teaser video was accepted',
                    },
                    videoCall: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.accepted.videoCall',
                      defaultMessage: 'Your Video Call Teaser video was accepted',
                    },
                    videoAds: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.accepted.videoAds',
                      defaultMessage: 'Your video ad was accepted',
                    },
                  }),
                },
                rejected: {
                  ...defineMessages({
                    portrait: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.rejected.portrait',
                      defaultMessage: 'Your portrait teaser video was rejected',
                    },
                    landscape: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.rejected.landscape',
                      defaultMessage: 'Your landscape teaser video was rejected',
                    },
                    videoCall: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.rejected.videoCall',
                      defaultMessage: 'Your Video Call Teaser video was rejected',
                    },
                    videoAds: {
                      id: 'pages.layout.header.notifications.mappings.promotools.status.rejected.videoAds',
                      defaultMessage: 'Your video ad was rejected',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
