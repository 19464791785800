import React from 'react';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import Spinner from 'components/loading';

import { testId } from '../notifications.settings';

import messages from './content.i18n';
import styles from './content.module.scss';

interface LoadingElement {
  minimal: boolean;
}

const Loading: React.FunctionComponent<LoadingElement> = (props) => {
  const { minimal } = props;
  const { formatMessage } = useIntl();

  return (
    <Html.div
      testId={testId.contentLoading}
      className={[styles.loading, 'row', !minimal && 'h-100', 'align-items-center']}
    >
      <Html.div>
        <Spinner delay={0} />
        {!minimal && (
          <Html.p className="text-center mt-4">
            {formatMessage(messages.pages.layout.header.notifications.content.loading)}
          </Html.p>
        )}
      </Html.div>
    </Html.div>
  );
};

export default Loading;
