import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            fanclub: {
              subscribed: defineMessages({
                silver: {
                  id: 'pages.layout.header.notifications.mappings.fanclub.subscribed.silver',
                  defaultMessage: '<b>{memberNick}</b> has subscribed to your Fan Club as a Silver member',
                },
                bronze: {
                  id: 'pages.layout.header.notifications.mappings.fanclub.subscribed.bronze',
                  defaultMessage: '<b>{memberNick}</b> has subscribed to your Fan Club as a Bronze member',
                },
                gold: {
                  id: 'pages.layout.header.notifications.mappings.fanclub.subscribed.gold',
                  defaultMessage: '<b>{memberNick}</b> has subscribed to your Fan Club as a Gold member',
                },
              }),

              ...defineMessages({
                resubscribed: {
                  id: 'pages.layout.header.notifications.mappings.fanclub.resubscribed',
                  defaultMessage: '<b>{memberNick}</b> has resubscribed to you!',
                },
                unsubscribed: {
                  id: 'pages.layout.header.notifications.mappings.fanclub.unsubscribed',
                  defaultMessage:
                    '<b>{memberNick}</b> just unsubscribed from your Fan Club. Try chatting with them and convince them to stay!',
                },
              }),
            },
          },
        },
      },
    },
  },
};
